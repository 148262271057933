import Vue from 'vue'
import App from './App.vue'
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import { v4 as uuidv4 } from 'uuid'

Vue.config.productionTip = false

let token = localStorage.getItem('authToken')
if (!token) {
  token = uuidv4()
  localStorage.setItem('authToken', token)
}

const url = process.env.API_ENDPOINT ? process.env.API_ENDPOINT : 'http://192.168.178.107:3000'

const socketConnection = SocketIO(url, {
  auth: {
    token
  }
})

Vue.use(new VueSocketIO({
  connection: socketConnection
}))

new Vue({
  render: h => h(App)
}).$mount('#app')
