<template>
  <div id="app">
    <Graph :graph-data="graphData" />
  </div>
</template>

<script>
import Graph from './components/Graph.vue'

export default {
  name: 'App',
  components: {
    Graph
  },
  data: () => {
    return {
      nodes: [],
      nodesTable: [],
      links: []
    }
  },
  computed: {
    graphData () {
      return {
        nodes: [...this.nodes],
        links: [...this.links]
      }
    }
  },
  sockets: {
    initData (data) {
      this.nodes = data.nodes
      this.links = data.links

      this.nodesTable = {}
      this.nodes.forEach((node, index) => {
        this.nodesTable[node.id] = index
      })
    },
    updateData (data) {
      const nodes = [...this.nodes]
      const newNodes = []

      data.nodes.forEach((node) => {
        if (typeof this.nodesTable[node.id] === 'undefined') {
          newNodes.push(node)
        } else {
          const index = this.nodesTable[node.id]
          Object.assign(nodes[index], node)
        }
      })

      newNodes.forEach((node) => {
        nodes.push(node)
        this.nodesTable[node.id] = nodes.length - 1
      })

      const links = [...this.links, ...data.links]

      this.nodes = nodes
      this.links = links
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn {
  background: black;
  border: 1px solid white;
  color: white;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0.5rem 1rem;

  &:hover {
    color: black;
    background: white;
  }
}

textarea {
  background: black;
  border: 1px solid white;
  margin: 10px;
  color: white;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
}
</style>
