import { render, staticRenderFns } from "./Whiteboard.vue?vue&type=template&id=7bee79b5&scoped=true&"
import script from "./Whiteboard.vue?vue&type=script&lang=js&"
export * from "./Whiteboard.vue?vue&type=script&lang=js&"
import style0 from "./Whiteboard.vue?vue&type=style&index=0&id=7bee79b5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bee79b5",
  null
  
)

export default component.exports