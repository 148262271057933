<template>
  <div v-if="isOpen" class="tooltip" :style="{ transform: `translate(${position.x}px, ${position.y}px)` }">
    <button class="btn" @click="addNode()">+</button>
    <button class="btn" v-if="canEdit" @click="editNode()">Edit</button>
  </div>
</template>

<script>
import EventBus from './../EventBus.js'

export default {
  name: 'Tooltip',
  data () {
    return {
      node: null,
      position: { x: 0, y: 0 }
    }
  },
  computed: {
    isOpen () {
      return this.node !== null
    },
    canEdit () {
      return this.node.editable
    }
  },
  methods: {
    close () {
      setTimeout(() => {
        this.node = null
        this.position = { x: 0, y: 0 }
      })
    },
    editNode () {
      EventBus.$emit('editNode', { node: this.node })
      this.close()
    },
    addNode () {
      EventBus.$emit('addNode', { node: this.node })
      this.close()
    }
  },
  mounted () {
    EventBus.$on('openTooltip', ({ node, position }) => {
      this.node = node
      this.position = position
    })

    EventBus.$on('closeTooltip', () => {
      if (this.isOpen) {
        this.close()
      }
    })

    window.addEventListener('mousewheel', () => {
      if (this.isOpen) {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .tooltip {
    position: fixed;
    left: 0;
    top: 0;
  }
</style>
