<template>
  <div class="graph-container">
    <div :class="{ graph: true, hover: isHover }" ref="graph">
    </div>
    <EditModal />
    <AddModal />
    <Tooltip />
  </div>
</template>

<script>
import ForceGraph from 'force-graph'
import EventBus from './../EventBus.js'
import EditModal from './EditModal.vue'
import AddModal from './AddModal.vue'
import Tooltip from './Tooltip.vue'

const graph = ForceGraph()

const imageCache = {}

const settings = {
  ph: 1,
  pv: 0.5,
  font: 'sans-serif',
  baseline: 'middle',
  fontSize: 2,
  lineHeight: 1.4,
  imageSize: 18
}

export default {
  name: 'Graph',
  components: {
    EditModal,
    AddModal,
    Tooltip
  },
  props: {
    graphData: Object
  },
  data () {
    return {
      isHover: false
    }
  },
  watch: {
    graphData: (newData) => {
      graph.graphData(newData)
    }
  },
  methods: {
    drawCircle (ctx, x, y, nodeSettings) {
      ctx.fillStyle = 'white'
      ctx.beginPath()
      ctx.arc(x, y, 3, 0, 2 * Math.PI)
      ctx.fill()

      return { w: 6, h: 6 }
    },
    drawText (ctx, x, y, text, nodeSettings) {
      const { ph, pv, font, baseline, lineHeight, fontSize } = nodeSettings

      ctx.font = `${fontSize}px ${font}`
      ctx.textBaseline = baseline

      const { lines, w, h } = this.prepareText(ctx, text, fontSize, lineHeight)

      ctx.fillStyle = 'white'
      ctx.fillRect(x - (w / 2) - ph, y - (h / 2) - pv, w + 2 * ph, h + pv * 2)
      ctx.fillStyle = 'black'

      lines.forEach((line, i) => {
        ctx.fillText(line, x - (w / 2), y - (h / 2) + (fontSize * lineHeight * 0.5) + (h / lines.length * i))
      })

      return { w: w + 2 * ph, h: h + pv * 2 }
    },
    prepareText (ctx, text, fontSize, lineHeight) {
      const dim = ctx.measureText(text)
      let w = dim.width
      let h = fontSize * lineHeight
      let lines = [text]

      if (w > 24) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '$&@')
        text = text.replace(/.{24}\S*\s+/g, '$&@')
        lines = text.split(/\s+@/)

        h *= lines.length
        let maxW = 0
        lines.forEach((line) => {
          const d = ctx.measureText(line)
          maxW = d.width > maxW ? d.width : maxW
        })
        w = maxW
      }
      return { lines, w, h }
    },
    drawImage (ctx, x, y, image, nodeSettings) {
      const { imageSize } = nodeSettings

      let imageObj = imageCache[image]
      if (!imageObj) {
        imageObj = new Image()
        imageObj.src = this.$socket.io.uri + image
        imageCache[image] = imageObj
      }

      ctx.drawImage(imageObj, x - imageSize / 2, y - imageSize / 2, imageSize, imageSize)

      return { w: imageSize, h: imageSize }
    },
    drawTextImage (ctx, x, y, image, text, nodeSettings) {
      const { ph, pv, font, baseline, lineHeight, fontSize, imageSize } = nodeSettings

      ctx.font = `${fontSize}px ${font}`
      ctx.textBaseline = baseline

      const { lines, w, h } = this.prepareText(ctx, text, fontSize, lineHeight)

      const top = y - (imageSize + h + (pv * 2)) / 2

      this.drawImage(ctx, x, top + imageSize / 2, image, nodeSettings)

      ctx.fillStyle = 'white'
      ctx.fillRect(x - (w / 2) - ph, top + imageSize, w + 2 * ph, h + pv * 2)

      ctx.fillStyle = 'black'
      lines.forEach((line, i) => {
        ctx.fillText(line, x - (w / 2), top + imageSize + pv + (fontSize * lineHeight * 0.5) + (h / lines.length * i))
      })

      return { w: Math.max(imageSize, w + 2 * ph), h: h + imageSize + (pv * 2) }
    }
  },
  mounted () {
    graph(this.$refs.graph)

    graph.nodeCanvasObject((node, ctx) => {
      const { x, y, image, text } = node
      let size = { w: 10, h: 10 }

      const nodeSettings = Object.assign({}, settings, node.settings)

      if (image && text) {
        size = this.drawTextImage(ctx, x, y, image, text, nodeSettings)
      } else if (image) {
        size = this.drawImage(ctx, x, y, image, nodeSettings)
      } else if (text) {
        size = this.drawText(ctx, x, y, text, nodeSettings)
      } else {
        size = this.drawCircle(ctx, x, y, nodeSettings)
      }

      node.size = size
    })

    graph.nodePointerAreaPaint((node, color, ctx, scale) => {
      if (!node.size) {
        return false
      }
      ctx.fillStyle = color
      ctx.fillRect(node.x - node.size.w / 2, node.y - node.size.h / 2, node.size.w, node.size.h)
    })

    graph.onNodeClick((node, event) => {
      const position = { x: event.pageX, y: event.pageY }
      EventBus.$emit('openTooltip', { node, position })
    })

    graph.onNodeHover((node, event) => {
      this.isHover = !!node
    })

    graph.onBackgroundClick(() => {
      EventBus.$emit('closeTooltip')
    })

    graph.linkCanvasObject((link, ctx, scale) => {
      const { target, source } = link

      ctx.strokeStyle = '#ffffffff'
      ctx.lineWidth = 0.1

      const vector = { x: target.x - source.x, y: target.y - source.y }
      const angle = Math.atan2(vector.x, vector.y) + Math.PI
      const arrowAngle = Math.PI / 4
      const arrowSize = 0.8
      const p1 = { x: Math.sin(angle + arrowAngle) * arrowSize, y: Math.cos(angle + arrowAngle) * arrowSize }
      const p2 = { x: Math.sin(angle - arrowAngle) * arrowSize, y: Math.cos(angle - arrowAngle) * arrowSize }
      const time = (Date.now() % 500) / 500

      const n = 10
      for (let i = 0; i < n; i++) {
        const c = {
          x: source.x + vector.x / n * (i + time),
          y: source.y + vector.y / n * (i + time)
        }

        ctx.beginPath()
        ctx.moveTo(c.x + p1.x, c.y + p1.y)
        ctx.lineTo(c.x, c.y)
        ctx.lineTo(c.x + p2.x, c.y + p2.y)
        ctx.stroke()
      }
    })
    graph.autoPauseRedraw(false)

    graph.graphData({
      nodes: [{ id: 1 }, { id: 2 }],
      links: [{ source: 1, target: 2 }]
    })
  }
}
</script>

<style scoped lang="scss">
  .graph {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;

    &.hover {
      cursor: pointer;
    }
  }
</style>
